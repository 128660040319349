<template>
  <div class="page">
    <div
      v-if="routeFrom"
      class="back-link"
      @click="$router.go(-1)"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span v-if="routeFrom && routeFrom.name === 'Tasks'">
        {{ capitalizeFirstLetter($tc('words.task', 2)) }}
      </span>
      <span v-if="routeFrom && routeFrom.name === 'DatasetDetail'">
        {{ capitalizeFirstLetter($tc('words.dataset', 1)) }}
      </span>
      <span v-else>
        {{ capitalizeFirstLetter($t('words.data')) }}
      </span>
    </div>
    
    <div
      v-if="currentTask"
      class="task-detail"
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
      >
        <h2>
          {{ capitalizeFirstLetter($tc('words.task', 1)) }} <pre style="display: contents">{{ currentTask.task_id }}</pre>
        </h2>

        <!-- Status -->
        <div
          class="row g-2 align-items-center"
          style="margin-top: 2rem;"
        >
          <div class="col-4">
            <strong>{{ $t('tasks.status') }}</strong>
          </div>
          <div
            class="col-8"
            :style="{color: currentTask.state === 'SUCCESS' ? 'green' : currentTask.state === 'FAILURE' ? 'red' : null }"
          >
            {{ currentTask.state_label.toUpperCase() }}
          </div>
        </div>

        <!-- Action-->
        <div
          v-if="currentTask.action"
          class="row g-2 align-items-center"
        >
          <div class="col-4">
            <strong>{{ $t('tasks.action') }}</strong>
          </div>
          <div
            class="col-8"
          >
            {{ currentTask.action }}
          </div>
        </div>

        <!-- Backend object -->
        <div v-if="currentTask.backend_object">
          <div
            v-if="currentTask.backend_object.label"
            class="row g-2 align-items-center"
          >
            <div class="col-4">
              <strong>{{ $t('tasks.object') }}</strong>
            </div>
            <div
              v-if="currentTask.backend_object['@class'] === 'dataset'"
              class="col-8"
            >
              <router-link
                v-slot="{ navigate }"
                :to="{ name: 'DatasetDetail', params: { id: currentTask.backend_object.id }}"
                custom
                class="custom-link"
              >
                <span
                  role="link"
                  @click="navigate"
                >
                  {{ currentTask.backend_object.label }}
                </span>
              </router-link>
            </div>
            <div
              v-else
              class="col-8"
            >
              {{ currentTask.backend_object.label }} ({{ currentTask.backend_object['@label'] }})
            </div>
          </div>
        </div>

        <!-- Target object -->
        <div v-if="currentTask.target_object">
          <div
            v-if="currentTask.target_object.label"
            class="row g-2 align-items-center"
          >
            <div class="col-4">
              <strong>{{ $t('tasks.target') }}</strong>
            </div>
            <div
              v-if="currentTask.target_object['@class'] === 'dataset'"
              class="col-8"
            >
              <router-link
                v-slot="{ navigate }"
                :to="{ name: 'DatasetDetail', params: { id: currentTask.target_object.id }}"
                custom
                class="custom-link"
              >
                <span
                  role="link"
                  @click="navigate"
                >
                  {{ currentTask.target_object.label }} ({{ currentTask.target_object['@label'] }})
                </span>
              </router-link>
            </div>
            <div
              v-else
              class="col-8"
            >
              {{ currentTask.target_object.label }}
            </div>
          </div>
        </div>

        <!-- TIMELINE -->
        <div class="row g-2 align-items-center">
          <div
            class="col-4"
            style="margin-top: 2rem; font-weight: bold;"
          >
            {{ $t('tasks.timeline') }}
          </div>
        </div>
        <div
          v-for="(step, index) in currentTask.stack"
          :key="`${index}-${step.state}`"
          class="row g-2 align-items-center"
        >
          <div
            class="col-4"
            :style="{color: step.state === 'SUCCESS' ? 'green' : step.state === 'FAILURE' ? 'red' : null }"
          >
            {{ step.state_label.toUpperCase() }}
          </div>
          <div class="col">
            {{
              new Date(step.datetime).toLocaleString('fr-FR', {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: '2-digit',
                minute: "2-digit",
                second: "2-digit"
              })
            }}
          </div>
        </div>

        <!-- MESSAGES -->
        <div
          v-if="currentTask.std_msgs && currentTask.std_msgs.length > 0"
          style="margin-top: 2rem;"
        >
          <div class="row g-2">
            <div
              class="col"
              style="font-weight: bold;"
            >
              {{ capitalizeFirstLetter($t('words.message')) }}(s)
            </div>
          </div>
          <div class="row g-2">
            <div
              v-for="[i, msg] of currentTask.std_msgs.entries()"
              :key="`${i}-${msg}`"
              class="col-12"
            >
              <pre class="pre-scrollable"><code>{{ msg }}</code></pre>
            </div>
          </div>
        </div>

        <!-- WARNS-->
        <div
          v-if="currentTask.warns && currentTask.warns.length > 0"
          style="margin-top: 2rem;"
        >
          <div class="row g-2">
            <div
              class="col"
              style="font-weight: bold;"
            >
              {{ capitalizeFirstLetter($t('words.warn')) }}(s)
            </div>
          </div>
          <div class="row g-2">
            <div
              v-for="[i, warn] of currentTask.warns.entries()"
              :key="`${i}-${warn}`"
              class="col-12"
            >
              <pre class="pre-scrollable"><code>{{ warn }}</code></pre>
            </div>
          </div>
        </div>

        <!-- ERRORS-->
        <div
          v-if="currentTask.errors && currentTask.errors.length > 0"
          style="margin-top: 2rem;"
        >
          <div class="row g-2">
            <div
              class="col"
              style="font-weight: bold;"
            >
              {{ capitalizeFirstLetter($t('words.error')) }}(s)
            </div>
          </div>
          <div class="row g-2">
            <div
              v-for="[i, error] of currentTask.errors.entries()"
              :key="`${i}-${error}`"
              class="col-12"
            >
              <pre class="pre-scrollable"><code>{{ error }}</code></pre>
            </div>
          </div>
        </div>

        <div v-if="currentTask.target_object && currentTask.target_object['@class'] === 'dataset'">
          <div class="row g-2 align-items-center">
            <div
              class="col-4"
              style="margin-top: 2rem; font-weight: bold;"
            >
              {{ capitalizeFirstLetter($tc('words.dataset', 1)) }}
            </div>
          </div>
          <div class="row g-2 align-items-center">
            <div class="row g-2 align-items-center">
              <div class="col">
                <router-link
                  v-slot="{ navigate }"
                  :to="{ name: 'DatasetDetail', params: { id: currentTask.target_object.id }}"
                  custom
                  class="custom-link"
                >
                  <span
                    role="link"
                    @click="navigate"
                  >
                    {{ currentTask.target_object.display_name }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'TaskDetail',

  data() {
    return {
      routeFrom: null,
      loading: false,
    };
  },

  computed: {
    ...mapState('dashboard', [
      'currentTask'
    ]),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  created() {
    this.loading = true;
    this.GET_TASK({ id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  mounted() {
    this.SET_RELOAD_INTERVAL_ID({
      name: 'tasks_reloading',
      interval: setInterval(() => {
        this.updateTask();
      }, process.env.VUE_APP_RELOAD_INTERVAL)
    });
  },

  methods: {
    ...mapMutations([
      'SET_RELOAD_INTERVAL_ID',
      'CLEAR_RELOAD_INTERVAL_ID'
    ]),
    ...mapActions('dashboard', [
      'GET_TASK'
    ]),

    updateTask() {
      if (!['SUCCESS', 'FAILURE'].includes(this.currentTask.state)) {
        this.loading = true;
        this.GET_TASK({ id: this.$route.params.id })
          .then(() => {
            if (['SUCCESS', 'FAILURE'].includes(this.currentTask.state)) {
              this.CLEAR_RELOAD_INTERVAL_ID('tasks_reloading');
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.CLEAR_RELOAD_INTERVAL_ID('tasks_reloading');
      }
    }
  }
};
</script>

<style lang="less" scoped>

.task-detail {
  max-width: 1000px;
  margin: 1rem auto;
}

.row {
  margin: 1rem 0;
  font-size: 1.2rem;
}

code {
  display: block;
  white-space: pre-wrap   
}

.custom-link {
  cursor: pointer;
  color: @blue;
}
.custom-link:hover {
  text-shadow: @blue 1px 0 1px;;
}

</style>
